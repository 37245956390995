<template>
  <div class="grid__container flex-center chapter-actions">
    <div @click="viewChapter" v-if="canRead">
      <va-icon name="fa fa-eye" :size="21" class="action-icon pointer" />
    </div>

    <div @click="editChapter" v-if="selectedMangaMode !== 'view' && canEdit">
      <va-icon name="fa fa-edit" :size="21" class="action-icon pointer" />
    </div>
    <notify-users-button :chapterId="chapterId" v-if="canNotifySubscribers" />
    <div
      @click="showDeleteChapterModal = true"
      v-if="selectedMangaMode !== 'view' && canDelete"
    >
      <va-icon name="fa fa-trash-o" :size="21" class="action-icon pointer" />
    </div>
    <va-modal
      v-model="showDeleteChapterModal"
      size="small"
      title="Delete chapter"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="deleteSelectedChapter"
    >
      Are you sure you want to delete chapter?
      <p v-html="chapterLabel"></p>
    </va-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { deleteChapter } from '../../../apollo/api/mangas';
import { isAllowed } from '../../../mixins/utils';
import NotifyUsersButton from './NotifyUsersButton.vue';

export default {
  name: 'ChapterActionsButttons',
  components: { NotifyUsersButton },
  props: {
    chapterId: String,
    chapterLabel: String,
  },
  data() {
    return {
      showDeleteChapterModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedMangaMode',
      'selectedChapterMode',
      'selectedChapterId',
      'ACCESS_CODES',
      'RESOURCE_CODES',
    ]),
    canRead() {
      return isAllowed([
        this.RESOURCE_CODES.CHAPTER([
          this.ACCESS_CODES.LIST,
          this.ACCESS_CODES.READ,
        ]),
      ]);
    },
    canEdit() {
      return isAllowed([
        this.RESOURCE_CODES.CHAPTER([
          this.ACCESS_CODES.LIST,
          this.ACCESS_CODES.EDIT,
        ]),
      ]);
    },
    canDelete() {
      return isAllowed([
        this.RESOURCE_CODES.CHAPTER([
          this.ACCESS_CODES.LIST,
          this.ACCESS_CODES.DELETE,
        ]),
      ]);
    },
    canNotifySubscribers() {
      return isAllowed([this.RESOURCE_CODES.MANGA([this.ACCESS_CODES.NOTIFY])]);
    },
  },
  methods: {
    ...mapMutations(['setSelectedChapter']),
    viewChapter() {
      this.setSelectedChapter({ id: this.chapterId, mode: 'view' });
    },
    editChapter() {
      if (this.selectedMangaMode !== 'view') {
        this.setSelectedChapter({ id: this.chapterId, mode: 'edit' });
      } else {
        // Manga is in view mode
        this.showToast('Selected manga is opened in view mode', {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
    },
    async deleteSelectedChapter() {
      if (this.selectedMangaMode !== 'view') {
        await deleteChapter(this.chapterId);
        this.showToast('Genre deleted successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        window.location.reload();
      } else {
        // Manga is in view mode
        this.showToast('Selected manga is opened in view mode', {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.chapter-actions {
  border-left: 2px dashed #5b656c;
}

.chapter-actions > * {
  margin: 5px;
  padding: 2px;

  &:hover {
    border: 1px solid #6a7277;
    border-radius: 4px;
  }
}
</style>
