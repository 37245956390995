<template>
  <div style="display: inline-block;">
    <va-button-group flat color="secondary">
      <va-button @click="showAddPagesModal = true"> Add new page(s)</va-button>
    </va-button-group>
    <va-modal
      v-model="showAddPagesModal"
      title="Chapter page(s)"
      :hideDefaultActions="true"
    >
      <div class="image-upload-container">
        <UploadImages
          ref="addChapterPages"
          :max="maxNoOfImages"
          :maxError="`Only ${maxNoOfImages} pages can be uploaded at a time`"
          uploadMsg="Upload pages"
          fileError="Only images are accepted"
          clearAll="Clear"
          @changed="handleImages"
        />
      </div>
      <template slot="actions">
        <div class="row">
          <va-button @click="closeModal" :disabled="apiLoading"
          >Cancel</va-button
          >
          <va-button @click="uploadPages" :disabled="apiLoading"
          >Add page(s)</va-button
          >
        </div>
      </template>
    </va-modal>
  </div>
</template>

<script>
import UploadImages from 'vue-upload-drop-images';
import { mapGetters } from 'vuex';
import { addChapterPages } from '../../../../../apollo/api/mangas';
export default {
  name: 'AddFileModal',
  components: { UploadImages },
  data() {
    return {
      showAddPagesModal: false,
      pages: [],
      apiLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedChapterId',
      'maxNoOfImages',
      'maxImageSizeInBytes',
    ]),
    getSizeInMB() {
      return this.maxImageSizeInBytes / (1024 * 1024);
    },
  },
  methods: {
    closeModal() {
      this.pages = [];
      this.showAddPagesModal = false;
    },
    handleImages(files) {
      for (const f of files) {
        if (f.size > this.maxImageSizeInBytes) {
          alert(`Image size should not exceed ${this.getSizeInMB} MB`);
          if (this.$refs.addChapterPages) {
            // Reset images uploaded in the component
            this.$refs.addChapterPages.reset();
          }
          return;
        }
      }
      this.pages = files;
    },
    async uploadPages() {
      if (this.pages.length === 0) {
        this.showToast('At least add one page', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        return;
      }
      if (this.pages.length > this.maxNoOfImages) {
        this.showToast(`At max ${this.maxNoOfImages} pages can be added`, {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        return;
      }
      this.apiLoading = true;
      try {
        const response = await addChapterPages(
          this.selectedChapterId,
          this.pages,
        );
        if (response.addChapterPages.response === 'OK') {
          this.showToast('Chapter pages added successfully', {
            position: 'top-right',
            duration: 800,
            fullWidth: false,
          });
          this.apiLoading = false;
          this.closeModal();
          this.$emit('refreshComponent');
        }
      } catch (e) {
        console.log(e);
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
      this.closeModal();
    },
  },
};
</script>
