<template>
  <div style="display: inline-block">
    <va-button @click="showModal = true" :disabled="isLoading">
      Add scraper</va-button
    >
    <va-modal
      v-model="showModal"
      okText="Add scraper"
      :cancelText="$t('modal.cancel')"
      @ok="submitChapter()"
    >
      <div class="flex">
        <div class="row">
          <div class="flex xs12 md12">
            <p class="display-4">Manga scraper</p>
            <br />
            <div class="row">
              <div class="flex xs6 md6">
                <va-select
                  label="Scraper"
                  placeholder="Select scraper"
                  v-model="selectedScraper"
                  :options="scrapers"
                  noClear
                  required
                />
                <va-input
                  class="mb-4"
                  v-model="newScraper.cronjobString"
                  label="Crontab"
                  placeholder="Crontab string"
                  readonly
                />
              </div>
              <div class="flex xs6 md5 offset--md1">
                <va-input
                  label="Site manga ID"
                  placeholder="Site's manga ID"
                  type="text"
                  v-model="newScraper.siteMangaId"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="flex xs12 md12">
                <VueCronEditorBuefy
                  v-model="newScraper.cronjobString"
                  :visibleTabs="['daily', 'weekly', 'monthly']"
                />
              </div>
            </div>
            <div class="row">
              <va-toggle
                label="Enabled"
                color="primary"
                v-model="newScraper.enabled"
                small
              />
            </div>
          </div>
        </div>
      </div>
    </va-modal>
  </div>
</template>

<script>
import VueCronEditorBuefy from 'vue-cron-editor-buefy';
import { BASE_NEW_SCRAPER } from '../../../../constants/defaultValues';
import { mapGetters } from 'vuex';
import {
  createMangaScraper,
  fetchScrapers,
} from '../../../../apollo/api/mangaScrapers';

export default {
  name: 'AddScraperModal',
  components: { VueCronEditorBuefy },
  data() {
    return {
      showModal: false,
      apiLoading: false,
      newScraper: { ...BASE_NEW_SCRAPER },
      scrapers: [],
      selectedScraper: '',
    };
  },
  watch: {
    showModal(newVal, oldVal) {
      if (newVal) {
        this.newScraper = { ...this.newScraper, ...BASE_NEW_SCRAPER };
      } else {
        this.newScraper = BASE_NEW_SCRAPER;
      }
    },
    selectedScraper(newVal, _oldVal) {
      if (newVal) {
        this.newScraper.scraperId = newVal.id;
      }
    },
  },
  computed: {
    ...mapGetters(['selectedMangaId', 'isLoading']),
  },
  created() {
    this.newScraper.mangaId = this.selectedMangaId;
  },
  async mounted() {
    await this.loadScraperOptions();
  },
  methods: {
    async loadScraperOptions() {
      this.apiLoading = true;
      this.scrapers = [];

      try {
        const { scrapersList } = await fetchScrapers();
        scrapersList.map(scraper =>
          this.scrapers.push({ id: scraper.id, text: scraper.alias })
        );
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    },
    async submitChapter() {
      // Poor man's frontend validation
      if (this.newScraper.scraperId == null) {
        this.showToast('Please select a scraper', {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
        return;
      }

      if (this.newScraper.siteMangaId == '') {
        this.showToast("Please provide site's manga id for scraping", {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
        return;
      }

      if (this.newScraper.cronjobString == '') {
        this.showToast('Please provide valid cronjob string', {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
        return;
      }

      this.apiLoading = true;
      try {
        const { createMangaScraperRelation } = await createMangaScraper({
          ...this.newScraper,
          mangaId: this.selectedMangaId,
        });
        if (
          createMangaScraperRelation &&
          createMangaScraperRelation.mangaScraperRelation &&
          createMangaScraperRelation.mangaScraperRelation.id
        ) {
          // Tell parent about this new child
          this.$emit(
            'success',
            createMangaScraperRelation.mangaScraperRelation
          );
          this.showToast('Manga scraper created successfully', {
            position: 'top-right',
            duration: 800,
            fullWidth: false,
          });
        }
      } catch (e) {
        console.log(e);
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.newScraper = { ...BASE_NEW_SCRAPER };
      this.apiLoading = false;
    },
  },
};
</script>
