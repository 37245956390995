<template>
  <div>
    <div class="row align--center justify-content--start" style="padding: 10px">
      <div class="flex xs12 md6">
        <add-scraper-modal @success="updateMangaScrapersArray" />
      </div>
      <div class="flex xs12 md2 offset--md4">
        <va-select
          v-model="perPage"
          :label="$t('tables.perPage')"
          :options="perPageOptions"
          noClear
        />
      </div>
    </div>
    <div class="row align--center">
      <div class="flex xs12 md12">
        <loader v-if="isLoading" />
        <data-table
          ref="scrapertable"
          :fields="fields"
          :data="mangaScrapers"
          :per-page="pagination.limit"
          :total-pages="pagination.pages"
          :currentPage="pagination.currentPage"
          :api-mode="true"
          :expandableRow="expandableRow"
          @page-selected="loadMangaScrapers"
          v-else
        >
          <template slot="scraper" slot-scope="props">
            {{ props.rowData.scraper.alias }} | {{ props.rowData.siteMangaId }}
            <a class="" :href="getMangaSiteURL(props.rowData)" target="_blank">
              <va-icon name="fa fa-external-link" :size="21" />
            </a>
          </template>
          <template slot="totalChaptersScraped" slot-scope="props">
            {{
              props.rowData.totalChaptersScraped
                ? props.rowData.totalChaptersScraped
                : 'NA'
            }}
          </template>
          <template slot="lastScrapedAt" slot-scope="props">
            <p v-if="props.rowData.lastSuccessfulScrap">
              {{ props.rowData.lastSuccessfulScrap | validDateFormat }}
            </p>
            <p v-else>NA</p>
          </template>
          <template slot="actions" slot-scope="props">
            <scraper-row-action
              :mangaScraper="props.rowData"
              :scrapers="scrapers"
              :isDetailedRowVisible="
                isDetailedRowVisbleForRowId(props.rowData.id)
              "
              @moreInfoButtonClick="handleMoreInfoButtonClick"
              @update="updateMangaScrapersArray"
              @delete="deleteFromMangaScrapersArray"
            />
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { setTitle } from '../../../../mixins/utils';
import { validDateFormat } from '../../../../mixins/filters';
import { MANGA_SCRAPER_TABLE_FIELDS } from '../../../../constants/tableFields';
import DataTable from '../../../../components/DataTable';
import Loader from '../../../../components/Loader';
import AddScraperModal from './AddScraperModal.vue';
import {
  ScraperRowAction,
  ScrapingTasksExpandedRow,
} from '../../../../components/ScraperRow';

import {
  fetchScrapers,
  fetchMangaScrapers,
} from '../../../../apollo/api/mangaScrapers';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    Loader,
    AddScraperModal,
    DataTable,
    ScraperRowAction,
    ScrapingTasksExpandedRow,
  },
  data() {
    return {
      perPageOptions: ['20', '40', '100', '200', '500', 'All'],
      perPage: '20',
      mangaScrapers: [],
      scrapers: [],
      pagination: {
        limit: 20,
        currentPage: 1,
        pages: 0,
        total: 0,
      },
      expandableRow: ScrapingTasksExpandedRow,
    };
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'selectedMangaId',
      'selectedMangaTitle',
      'selectedMangaMode',
    ]),
    fields() {
      return MANGA_SCRAPER_TABLE_FIELDS;
    },
  },
  filters: {
    validDateFormat,
  },
  watch: {
    perPage: function(newVal) {
      if (newVal === 'All') {
        this.pagination.limit = 100;
      } else {
        this.pagination.limit = parseInt(newVal);
      }
      this.loadMangaScrapers();
    },
  },
  created() {
    setTitle(`Scraper - ${this.selectedMangaTitle}`);
  },
  async mounted() {
    if (this.selectedMangaId) {
      await this.loadScrapers();
      await this.loadMangaScrapers();
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    updateMangaScrapersArray(updatedMangaScraper) {
      let updatedFlag = false;
      const newMangaScrapers = [];
      for (const mangaScraper of this.mangaScrapers) {
        if (mangaScraper.id == updatedMangaScraper.id) {
          newMangaScrapers.push(updatedMangaScraper);
          updatedFlag = true;
          break;
        }
        newMangaScrapers.push(mangaScraper);
      }
      if (!updatedFlag) {
        newMangaScrapers.push(updatedMangaScraper);
      }
      this.mangaScrapers = newMangaScrapers;
    },
    deleteFromMangaScrapersArray(mangaScraper) {
      const newMangaScrapers = this.mangaScrapers.filter(
        e => e.id != mangaScraper.id
      );
      this.mangaScrapers = newMangaScrapers;
    },
    getMangaSiteURL(mangaScraper) {
      if (!!mangaScraper.scraper.linkFormat)
        return mangaScraper.scraper.linkFormat.replace(
          '{0}',
          mangaScraper.siteMangaId
        );
      return '';
    },
    async loadScrapers() {
      this.scrapers = [];

      try {
        const { scrapersList } = await fetchScrapers();
        this.scrapers = {};
        scrapersList.map(scraper => {
          if (!(scraper.id in this.scrapers)) {
            this.scrapers[scraper.id] = scraper;
          }
        });
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
    },
    async loadMangaScrapers(page = 1) {
      this.setLoading(true);
      try {
        const { mangaScrapersList } = await fetchMangaScrapers(
          this.selectedMangaId,
          this.pagination.limit,
          page
        );
        this.mangaScrapers = mangaScrapersList.mangaRelations;
        this.pagination = {
          ...this.pagination,
          currentPage: mangaScrapersList.currentPage,
          pages: mangaScrapersList.pages,
          total: mangaScrapersList.total,
        };
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
    handleMoreInfoButtonClick(rowId) {
      this.$refs.scrapertable.handleDetailRowToggleById(rowId);
    },
    isDetailedRowVisbleForRowId(rowId) {
      return this.$refs.scrapertable.isDetailedRowVisible(rowId);
    },
  },
};
</script>
