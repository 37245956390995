<template>
  <va-card style="overflow-x: auto">
    <div class="row">
      <div class="flex xs12">
        <va-tabs
          v-model="tabValue"
          style="width: 100%; min-width: 250px"
          @input="redirectPage"
        >
          <va-tab> Manga Info </va-tab>
          <va-tab v-if="!isAddMode"> Chapters </va-tab>
          <va-tab v-if="!isAddMode"> Scrapers </va-tab>
        </va-tabs>
        <manga-details ref="mangaDetails" v-if="tabValue === 0" />
        <chapters ref="chapters" v-if="!isAddMode && tabValue === 1" />
        <scrapers ref="scrapers" v-else-if="!isAddMode && tabValue === 2" />
      </div>
    </div>
  </va-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { getMangaTitleFromStorage } from '../../mixins/utils';
import MangaDetails from './MangaDetails';
import Chapters from './Chapters';
import Scrapers from './Scrapers';

export default {
  name: 'manga-info',
  components: { MangaDetails, Chapters, Scrapers },
  data() {
    return {
      tabs: [
        {
          title: 'Manga Info',
          component: MangaDetails,
        },
        {
          title: 'Chapters',
          component: Chapters,
        },
        {
          title: 'Scrapers',
          component: Scrapers,
        },
      ],
      tabValue: 0,
    };
  },
  computed: {
    ...mapGetters(['selectedMangaId', 'isMangaSaved', 'isChapterSaved']),
    isAddMode() {
      return this.$route.name === 'add-manga';
    },
  },
  created() {
    if (!this.isAddMode) {
      const mode = this.$route.name === 'view-manga' ? 'view' : 'edit';
      const title = getMangaTitleFromStorage(this.$route.params.id);
      this.setSelectedManga({ id: this.$route.params.id, mode, title });

      // If 'chapters' is in route, then set tabValue = 1
      if (
        this.$route.name === 'view-chapters' ||
        this.$route.name === 'view-chapter-id' ||
        this.$route.name === 'edit-chapters' ||
        this.$route.name === 'edit-chapters-id' ||
        this.$route.name === 'view-chapter-id-in-edit'
      ) {
        this.tabValue = 1;
      } else if (
        this.$route.name === 'view-scrapers' ||
        this.$route.name === 'edit-scrapers'
      ) {
        this.tabValue = 2;
      } else {
        this.tabValue = 0;
      }
    }
  },
  methods: {
    ...mapMutations(['setSelectedManga']),
    redirectPage(newTabVal) {
      if (newTabVal === 0) {
        if (this.$route.path.startsWith('/mangas/edit')) {
          this.$router.push({
            name: 'edit-manga',
            params: { id: this.selectedMangaId },
          });
        } else {
          this.$router.push({
            name: 'view-manga',
            params: { id: this.selectedMangaId },
          });
        }

        window.location.reload();
      } else if (newTabVal === 1) {
        if (this.$route.path.startsWith('/mangas/edit')) {
          this.$router.push({
            name: 'edit-chapters',
            params: { id: this.selectedMangaId },
          });
        } else {
          this.$router.push({
            name: 'view-chapters',
            params: { id: this.selectedMangaId },
          });
        }
      } else if (newTabVal === 2) {
        if (this.$route.path.startsWith('/mangas/edit')) {
          this.$router.push({
            name: 'edit-scrapers',
            params: { id: this.selectedMangaId },
          });
        } else {
          this.$router.push({
            name: 'view-scrapers',
            params: { id: this.selectedMangaId },
          });
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // Check if any of the ref is present
    if (!this.isMangaSaved || !this.isChapterSaved) {
      const answer = window.confirm(
        'Do you really want to leave? you have unsaved changes!'
      );
      if (answer) {
        localStorage.removeItem(this.selectedMangaId);
        next();
      }
    } else {
      localStorage.removeItem(this.selectedMangaId);
      next();
    }
  },
};
</script>
