<template>
  <vue-gallery-slideshow
    :images="images"
    :index="currentIndex"
    @close="closeMe"
  ></vue-gallery-slideshow>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
export default {
  name: 'Gallery',
  components: {
    VueGallerySlideshow,
  },
  props: {
    images: {
      type: Array,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    closeMe(_) {},
  },
};
</script>

<style lang="scss">
.page-container {
  display: flex;
  width: 192px;
  border: 1px solid #e8e5e5;
  border-radius: 10px;
  padding: 4px;
  transition-delay: 0.2;

  &:hover {
    border: 2px solid #726e6e;
  }
}

.page-thumbnail {
  width: 182px;
  height: auto;
}
</style>
