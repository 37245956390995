<template>
  <div class="row">
    <div class="flex xs12 md12">
      <div class="row align--center">
        <div class="flex xs12 md2">
          <p class="display-4" style="margin-bottom: 10px;">
            Pages
          </p>
        </div>
        <div class="flex xs12 md8 offset--md2" v-if="!isViewMode">
          <add-file-modal
            @refreshComponent="refreshComponent"
            v-if="!useAltSrc && this.selectedPages.length === 0 && isPagesSaved"
          />
          <va-button
            color="success"
            @click="updatePageOrder"
            style="display: inline-block;"
            v-if="!isPagesSaved && !!pages && pages.length > 0"
          >
            Update page order
          </va-button>
          <va-button
            color="danger"
            @click="deleteSelectedPages"
            style="display: inline-block;"
            v-if="selectedPages.length > 0"
            :disabled="apiLoading"
          >
            Delete
          </va-button>
          <va-button
            color="warning"
            @click="resetPageOrder"
            style="display: inline-block;"
            v-if="
              (!isPagesSaved && !!pages && pages.length > 0) ||
                selectedPages.length > 0
            "
            :disabled="apiLoading"
          >
            Reset
          </va-button>
        </div>
      </div>
      <div class="row">
        <div class="flex xs12 md12" v-if="!!pages && pages.length == 0">
          <p class="display-6">No pages have been uploaded</p>
        </div>
        <grid
          :draggable="this.selectedPages.length === 0"
          :sortable="true"
          :items="pages"
          :cellWidth="200"
          :cellHeight="300"
          v-else
          ref="pageGrid"
          @click="showGallery"
          @dragstart="addShakeClass"
          @dragend="pageReorder"
        >
          <template slot="cell" slot-scope="props">
            <Page
              :class="selectedPage === props.index ? 'selected-page' : ''"
              :imageSource="pageURL(props.item)"
              :useAltSrc="useAltSrc"
              @refreshComponent="refreshComponent"
            />
          </template>
        </grid>
      </div>
    </div>
    <gallery
      :images="galleryConfig.images"
      :currentIndex="galleryConfig.currentIndex"
      v-if="galleryConfig.show"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import Grid from 'vue-js-grid/src/Grid';
import {
  deleteChapterPages,
  updateChapterPageOrder,
} from '../../../../../apollo/api/mangas';
import Gallery from '../../../../../components/Gallery';
import Page from './Page/index.vue';
import AddFileModal from './AddFileModal.vue';

export default {
  components: { Grid, Page, AddFileModal, Gallery },
  props: {
    pages: {
      type: Array,
    },
    useAltSrc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiLoading: false,
      selectedPage: -1,
      galleryConfig: {
        show: true,
        images: [],
        currentIndex: 0,
      },
      newPageArray: [],
    };
  },
  computed: {
    ...mapGetters([
      'selectedMangaId',
      'selectedChapterId',
      'selectedChapterMode',
      'isPagesSaved',
      'selectedPages',
      's3PublicURL',
    ]),
    isViewMode() {
      return this.selectedChapterMode === 'view';
    },
  },
  watch: {
    newPageArray(newVal) {
      if (_.isEqual(newVal, this.pages)) {
        this.setChapterPagesSavedState(true);
      } else {
        this.setChapterPagesSavedState(false);
      }
    },
  },
  methods: {
    ...mapMutations([
      'setSelectedChapter',
      'setChapterSavedState',
      'setChapterPagesSavedState',
      'setSelectedPages',
    ]),
    pageURL(pageId) {
      if (this.useAltSrc) {
        return pageId.replace(
          'https://xn--cckb8hk3i.com/',
          'https://s3.eu-central-1.wasabisys.com/xn--cckb8hk3i.com/',
        );
      } else {
        return `${this.s3PublicURL}/${this.selectedMangaId}/${this.selectedChapterId}/${pageId}`;
      }
    },
    pageReorder(e) {
      this.newPageArray = e.items.map(p => p.item);
      this.removeShakeClass();
    },
    resetPageOrder() {
      if (this.selectedPages.length > 0) {
        this.setSelectedPages([]);
      }

      if (this.$refs.pageGrid) {
        // Reseting chapter pages using ref
        this.$refs.pageGrid.list = this.pages.map((item, index) => {
          return {
            item,
            index: index,
            sort: index,
          };
        });

        this.newPageArray = this.pages;
      }
    },
    refreshComponent() {
      this.$emit('refreshComponent');
    },
    async updatePageOrder() {
      this.apiLoading = true;
      try {
        let response = null;
        if (this.useAltSrc) {
          response = await updateChapterPageOrder(
            this.selectedChapterId,
            undefined,
            this.newPageArray,
          );
        } else {
          response = await updateChapterPageOrder(
            this.selectedChapterId,
            this.newPageArray,
          );
        }

        // Update pages of the model here
        this.$emit('refreshChapterPages', this.newPageArray);

        if (response.updateChapterPageIndices.response === 'OK') {
          this.setChapterPagesSavedState(true);
          this.showToast('Chapter pages updated successfully', {
            position: 'top-right',
            duration: 800,
            fullWidth: false,
          });
        }
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    },
    async deleteSelectedPages() {
      if (this.selectedPages.length === 0) return;
      this.apiLoading = true;
      try {
        const response = await deleteChapterPages(
          this.selectedChapterId,
          this.selectedPages,
        );
        if (response.deleteChapterPages.response === 'OK') {
          this.showToast('Chapter pages deleted successfully', {
            position: 'top-right',
            duration: 800,
            fullWidth: false,
          });
          this.setSelectedPages([]);
          this.apiLoading = false;
          this.refreshComponent();
        }
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    },
    addShakeClass(ev) {
      this.selectedPage = ev.index;
    },
    removeShakeClass() {
      this.selectedPage = -1;
    },
    showGallery(ev) {
      // Only trigger gallery when clicked on the page tag
      if (ev.event.target.className === 'page-control-container') {
        this.galleryConfig.images = ev.items.map(item =>
          this.pageURL(item.item),
        );
        this.galleryConfig.currentIndex = ev.index;
        this.galleryConfig.show = true;
      }
    },
  },
};
</script>

<style>
.selected-page {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  /* 10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  } */

  20% {
    transform: translate(-3px, 0) rotate(1deg);
  }

  /* 30% {
    transform: translate(3px, 2px) rotate(0deg);
  } */

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  /* 50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  } */

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  /* 70% {
    transform: translate(3px, 1px) rotate(-1deg);
  } */

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  /* 90% {
    transform: translate(1px, 2px) rotate(0deg);
  } */

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
