<template>
  <div style="margin-top: 20px">
    <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px">
      <va-tab>Scrapers</va-tab>
      <va-tab>Chapters</va-tab>
    </va-tabs>
    <manga-scrapers v-if="tabValue == 0" />
    <scraped-chapters v-else />
  </div>
</template>

<script>
import _ from 'lodash';
import { setTitle } from '../../../mixins/utils';
import MangaScrapers from './MangaScrapers';
import ScrapedChapters from './ScrapedChapters';

import { mapGetters } from 'vuex';

export default {
  components: { MangaScrapers, ScrapedChapters },
  data() {
    return {
      tabValue: 0,
    };
  },
  computed: {
    ...mapGetters(['selectedMangaTitle']),
  },
  filters: {},
  watch: {},
  created() {
    setTitle(`Scraper - ${this.selectedMangaTitle}`);
  },
  async mounted() {},
  methods: {},
};
</script>
