import { QUERIES, MUTATIONS } from '../schemas/tempChapter';
import { apolloClient } from '../index';

export const fetchTempChapters = async (
  mangaId,
  limit = 100,
  page = 1,
  sortBy = '-chapter'
) => {
  const { data } = await apolloClient.query({
    query: QUERIES.CHAPTERS,
    variables: { mangaId, limit, page, sortBy },
    errorPolicy: 'all',
  });
  return data;
};

export const fetchTempChapter = async id => {
  const { data } = await apolloClient.query({
    query: QUERIES.CHAPTER,
    variables: { id },
  });
  return data;
};

export const approveTempChapter = async chapterId => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.APPROVE_CHAPTER,
    variables: { chapterId },
  });
  return data;
};

export const updateTempChapter = async chapter => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.UPDATE_CHAPTER_INFO,
    variables: { ...chapter },
  });
  return data;
};

export const deleteTempChapter = async chapterId => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.DELETE_CHAPTER,
    variables: { chapterId },
  });
  return data;
};
