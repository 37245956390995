<template>
  <div @click="showModal">
    <va-icon
      name="fa fa-bell"
      :size="18"
      class="action-icon pointer notification-bell-icon"
    />
    <va-modal
      v-model="showNotificationConfirmationModal"
      size="small"
      title="Notify users for this chapter"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="notifyUsers"
    >
      Are you sure you want to notify ALL USERS who has subscribed to this
      manga?
      <!--
        TODO: Add some chapter details here
      -->
    </va-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { notifySubscribers } from '../../../apollo/api/mangas';

export default {
  props: {
    chapterId: String,
  },
  data() {
    return {
      showNotificationConfirmationModal: false,
    };
  },
  methods: {
    ...mapMutations(['setBackgroundLoading']),
    showModal() {
      this.showNotificationConfirmationModal = true;
    },
    async notifyUsers() {
      this.setBackgroundLoading(true);
      try {
        const response = await notifySubscribers(this.chapterId);
        if (response.notifyChapterMangaSubscribers.response === 'OK') {
          this.showToast('Users notified successfuly', {
            position: 'top-right',
            duration: 800,
            fullWidth: false,
          });
        }
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
        console.log(e);
      }
      this.setBackgroundLoading(false);
    },
  },
};
</script>

<style lang="scss">
.notification-bell-icon {
  color: #fdc200;
}
</style>
