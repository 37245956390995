<template>
  <div class="row">
    <div class="flex xs12 md12">
      <div class="row">
        <div class="flex xs12 md2">
          <p class="display-4" style="margin-bottom: 10px">Pages</p>
        </div>
        <div class="flex xs12 md12" v-if="!!pages && pages.length == 0">
          <p class="display-6">No pages have been uploaded</p>
        </div>
        <grid
          :draggable="false"
          :sortable="false"
          :items="pages"
          :cellWidth="200"
          :cellHeight="300"
          v-else
          ref="pageGrid"
          @click="showGallery"
        >
          <!-- @dragstart="addShakeClass"
          @dragend="pageReorder" -->
          <template slot="cell" slot-scope="props">
            <Page
              :imageSource="pageURL(props.item)"
              :index="props.index"
              @click="showGallery"
            />
          </template>
        </grid>
      </div>
    </div>
    <gallery
      :images="galleryPages"
      :currentIndex="galleryConfig.currentIndex"
      v-if="galleryConfig.show"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Grid from 'vue-js-grid/src/Grid';
import Gallery from '../../../../../../components/Gallery';
import Page from './Page.vue';

export default {
  components: { Grid, Page, Gallery },
  props: {
    pages: {
      type: Array,
    },
  },
  data() {
    return {
      galleryConfig: {
        show: false,
        currentIndex: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedMangaId',
      'selectedChapterId',
      'selectedChapterMode',
      's3TemporaryURL',
      'localS3Prefix',
    ]),
    isViewMode() {
      return this.selectedChapterMode === 'view';
    },
    galleryPages() {
      return this.pages.map((page) => this.pageURL(page));
    },
  },
  methods: {
    pageURL(pageId) {
      return `${this.s3TemporaryURL}/${this.localS3Prefix}/temp-chapters/${this.selectedMangaId}/${this.selectedChapterId}/${pageId}`;
    },
    showGallery(ev) {
      // Only trigger gallery when clicked on the page tag
      this.galleryConfig.currentIndex = ev.index;
      this.galleryConfig.show = true;
    },
  },
};
</script>
