<template>
  <div class="page-control-container" v-if="isEditMode">
    <p @click="selectPage">
      <va-icon
        class="option-button option-checkbox"
        :name="
          `glyphicon glyphicon-${
            isSelected ? 'record' : 'unchecked'
          } icon-right input-icon`
        "
        :style="isSelected ? 'color: #ee5050;' : ''"
        :size="20"
      />
    </p>
    <div class="option-container" v-if="selectedPages.length === 0">
      <p @click="setAsThumbnail">
        <va-icon
          class="option-button"
          name="glyphicon glyphicon-paperclip icon-left input-icon"
          :size="20"
        />
      </p>
      <p @click="$refs[`${pageId}_image`].click()">
        <va-icon
          class="option-button"
          name="glyphicon glyphicon-edit icon-left input-icon"
          :size="20"
        />
        <input
          :ref="`${pageId}_image`"
          type="file"
          style="display: none;"
          v-on:change="updatePageImage"
        />
      </p>
      <p @click="deletePageImage">
        <va-icon
          class="option-button option-checkbox"
          name="glyphicon glyphicon-remove-sign icon-left input-icon"
          :size="20"
        />
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  setChapterThumbnail,
  updateChapterPage,
  deleteChapterPage,
} from '../../../../../../apollo/api/mangas';
export default {
  props: {
    imageSource: {
      type: String,
    },
    useAltSrc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedChapterMode',
      'selectedChapterId',
      'selectedPages',
    ]),
    isEditMode() {
      return this.selectedChapterMode === 'edit' && this.useAltSrc === false;
    },
    pageId() {
      const contents = this.imageSource.split('/');
      return contents[contents.length - 1];
    },
    isSelected() {
      return this.selectedPages.includes(this.pageId);
    },
  },
  methods: {
    ...mapMutations(['pushToSelectedPages', 'removeFromSelectedPages']),
    selectPage() {
      if (!this.isSelected) {
        this.pushToSelectedPages(this.pageId);
      } else {
        this.removeFromSelectedPages(this.pageId);
      }
    },
    refreshComponent() {
      this.$emit('refreshComponent');
    },
    async setAsThumbnail() {
      if (this.apiLoading) return;
      this.apiLoading = true;
      try {
        await setChapterThumbnail(this.selectedChapterId, this.imageSource);
        this.showToast('Chapter thumbnail updated successfully', {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      } catch (e) {
        console.log(e);
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    },
    async updatePageImage({ target: { files = [] } }) {
      if (!files.length) {
        return;
      }
      const pageFile = files[0];
      if (this.apiLoading) return;
      this.apiLoading = true;
      try {
        const response = await updateChapterPage(
          this.selectedChapterId,
          this.pageId,
          pageFile,
        );
        if (response.updateChapterPage.response === 'OK') {
          this.apiLoading = false;
          this.showToast('Chapter page updated successfully', {
            position: 'top-right',
            duration: 1200,
            fullWidth: false,
          });
          this.refreshComponent();
        }
      } catch (e) {
        console.log(e);
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    },
    async deletePageImage() {
      if (this.apiLoading) return;
      this.apiLoading = true;
      try {
        const response = await deleteChapterPage(
          this.selectedChapterId,
          this.pageId,
        );
        if (response.deleteChapterPages.response === 'OK') {
          this.apiLoading = false;
          this.showToast('Chapter page deleted successfully', {
            position: 'top-right',
            duration: 1200,
            fullWidth: false,
          });
          this.refreshComponent();
        }
      } catch (e) {
        console.log(e);
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    },
  },
};
</script>

<style lang="scss">
.page-control-container {
  z-index: 500;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 192px;
  height: 274px;
  background-color: #5b656c60;
  border: 2px solid #5a5555;
  border-radius: 8px;
}

.option-button {
  margin: 2px;
  cursor: pointer;
  color: #969696;
  align-self: center;

  &:hover {
    color: #333030;
  }
}

.option-checkbox {
  &:hover {
    color: #ee5050;
  }
}

.page-control-container > .option-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  align-items: center;
  background: #fff7f7;
  border: 1px solid rgb(202, 201, 201);
  border-radius: 5px;
}
</style>
