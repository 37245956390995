<template>
  <div class="grid__container flex-center chapter-actions">
    <div @click="viewChapter">
      <va-icon name="fa fa-eye" :size="21" class="action-icon pointer" />
    </div>
    <div @click="editChapter" v-if="selectedMangaMode !== 'view'">
      <va-icon name="fa fa-edit" :size="21" class="action-icon pointer" />
    </div>
    <div
      @click="showDeleteChapterModal = true"
      v-if="selectedMangaMode !== 'view'"
    >
      <va-icon name="fa fa-trash-o" :size="21" class="action-icon pointer" />
    </div>
    <div @click="approveChapterModal = true" v-if="!approved">
      <va-icon name="fa fa-check" :size="21" class="action-icon pointer" />
    </div>
    <va-modal
      v-model="approveChapterModal"
      size="small"
      title="Approve chapter"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="approveSelectedChapter"
    >
      Are you sure you want to approve chapter?
      <p v-html="chapterLabel"></p>
    </va-modal>
    <va-modal
      v-model="showDeleteChapterModal"
      size="small"
      title="Delete chapter"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="deleteSelectedChapter"
    >
      Are you sure you want to delete chapter?
      <p v-html="chapterLabel"></p>
    </va-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  deleteTempChapter,
  approveTempChapter,
} from '../../../../apollo/api/tempChapters';

export default {
  name: 'ChapterActionsButttons',
  props: {
    chapterId: String,
    chapterLabel: String,
    approved: Boolean,
  },
  data() {
    return {
      showDeleteChapterModal: false,
      approveChapterModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedMangaMode',
      'selectedChapterMode',
      'selectedChapterId',
    ]),
  },
  methods: {
    ...mapMutations(['setSelectedChapter']),
    viewChapter() {
      this.setSelectedChapter({ id: this.chapterId, mode: 'view' });
    },
    editChapter() {
      if (this.selectedMangaMode !== 'view') {
        this.setSelectedChapter({ id: this.chapterId, mode: 'edit' });
      } else {
        // Manga is in view mode
        this.showToast('Selected manga is opened in view mode', {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
    },
    async deleteSelectedChapter() {
      if (this.selectedMangaMode !== 'view') {
        const response = await deleteTempChapter(this.chapterId);
        this.showToast('Temp chapter deleted successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        // window.location.reload();
      } else {
        // Manga is in view mode
        this.showToast('Selected manga is opened in view mode', {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
    },
    async approveSelectedChapter() {
      try {
        const response = await approveTempChapter(this.chapterId);
        this.showToast('Temp chapter approved successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        // window.location.reload();
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.chapter-actions {
  border-left: 2px dashed #5b656c;
}

.chapter-actions > * {
  margin: 5px;
  padding: 2px;

  &:hover {
    border: 1px solid #6a7277;
    border-radius: 4px;
  }
}
</style>
