import gql from 'graphql-tag';

export const QUERIES = {
  /** Chapter queries */
  CHAPTERS: gql`
    query FETCH_TEMP_CHAPTERS(
      $mangaId: String!
      $limit: Int
      $page: Int
      $sortBy: String
    ) {
      tempChaptersList(
        mangaId: $mangaId
        limit: $limit
        page: $page
        sortBy: $sortBy
      ) {
        chapters {
          id
          title
          chapter
          volume
          chapterIndex
          approved
          mangaRelation {
            scraper {
              name
            }
          }
        }
        currentPage
        total
        pages
      }
    }
  `,
  CHAPTER: gql`
    query FETCH_TEMP_CHAPTER($id: String) {
      tempChapterItem(id: $id) {
        id
        chapter
        chapterIndex
        volume
        title
        pages
        longStrip
        releaseDate
        scrapedAt
      }
    }
  `,
};

export const MUTATIONS = {
  UPDATE_CHAPTER_INFO: gql`
    mutation UPDATE_TEMP_CHAPTER_INFO(
      $id: ID
      $title: String
      $chapter: String
      $volume: Int
      $chapterIndex: Int
      $longStrip: Boolean
      $releaseDate: DateTime
    ) {
      updateTempChapter(
        chapterData: {
          id: $id
          title: $title
          chapter: $chapter
          volume: $volume
          chapterIndex: $chapterIndex
          longStrip: $longStrip
          releaseDate: $releaseDate
        }
      ) {
        chapter {
          id
          chapter
          chapterIndex
          volume
          title
          pages
          longStrip
          releaseDate
          scrapedAt
        }
      }
    }
  `,
  APPROVE_CHAPTER: gql`
    mutation APPROVE_TEMP_CHAPTER($chapterId: ID) {
      approveTempChapter(chapterId: $chapterId) {
        response
      }
    }
  `,
  DELETE_CHAPTER: gql`
    mutation DELETE_TEMP_CHAPTER($chapterId: ID) {
      deleteTempChapter(chapterId: $chapterId) {
        response
      }
    }
  `,
};
