<template>
  <div>
    <div class="row align--center justify-content--start">
      <div class="flex xs12 md6"></div>
      <div class="flex xs12 md2 offset--md4">
        <va-select
          v-model="perPage"
          :label="$t('tables.perPage')"
          :options="perPageOptions"
          noClear
        />
      </div>
    </div>
    <div class="row align--center">
      <div class="flex md10 lg11">
        <loader v-if="isLoading" />
        <div v-else>
          <div v-if="pagination.pages > 1" class="va-data-table__pagination">
            <va-pagination
              :value="pagination.currentPage"
              :visible-pages="3"
              :pages="pagination.pages"
              @input="loadChapters"
            />
          </div>
          <h4 v-if="chapters.length == 0">No new chapters scraped yet</h4>
          <chapter-row
            :class="!selectedChapterId ? 'item' : ''"
            v-for="chapter in chapters"
            :key="chapter.id"
            :chapter="chapter"
            v-else
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Loader from '../../../../components/Loader';
import ChapterRow from './ChapterRow';
import { fetchTempChapters } from '../../../../apollo/api/tempChapters';
import { mapGetters, mapMutations } from 'vuex';

const MAX_CHAPTER_COUNT = 2000;

export default {
  components: { Loader, ChapterRow },
  data() {
    return {
      perPageOptions: ['20', '40', '100', '200', '500', 'All'],
      perPage: '20',
      chapters: [],
      originalChaptersIndex: [],
      pagination: {
        limit: 20,
        currentPage: 1,
        pages: 0,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'selectedMangaId',
      'selectedMangaTitle',
      'selectedMangaMode',
      'selectedChapterId',
    ]),
  },
  async mounted() {
    if (this.selectedMangaId) {
      await this.loadChapters();
    }
  },
  watch: {
    perPage: function (newVal) {
      if (newVal === 'All') {
        this.pagination.limit = MAX_CHAPTER_COUNT;
      } else {
        this.pagination.limit = parseInt(newVal);
      }
      this.loadChapters();
    },
  },
  methods: {
    ...mapMutations(['setLoading']),
    async loadChapters(page = 1) {
      this.setLoading(true);
      try {
        const { tempChaptersList } = await fetchTempChapters(
          this.selectedMangaId,
          this.pagination.limit,
          page
        );

        this.chapters = tempChaptersList.chapters;
        this.pagination = {
          ...this.pagination,
          currentPage: tempChaptersList.currentPage,
          pages: tempChaptersList.pages,
          total: tempChaptersList.total,
        };
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
  },
};
</script>
