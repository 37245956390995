<template>
  <div
    style="padding: 4px; height: 300px;"
    @mouseenter="showControls = true"
    @mouseleave="showControls = false"
  >
    <transition name="fade">
      <page-control
        :imageSource="imageSource"
        :useAltSrc="useAltSrc"
        @refreshComponent="refreshComponent"
        v-if="showControls"
      />
    </transition>
    <div class="page-selected-class" v-if="isSelected">
      <va-icon
        name="glyphicon glyphicon-record"
        style="color: #ee5050;"
        :size="20"
      />
    </div>
    <div class="page-container">
      <va-inner-loading :loading="imageLoading">
        <img
          class="page-thumbnail"
          :src="imageSource"
          loading="lazy"
          draggable="false"
          @load="loaded"
        />
      </va-inner-loading>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageControl from './Controls.vue';
export default {
  components: { PageControl },
  props: {
    imageSource: {
      type: String,
    },
    useAltSrc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showControls: false,
      imageLoading: true,
    };
  },
  computed: {
    ...mapGetters(['selectedPages']),
    pageId() {
      const contents = this.imageSource.split('/');
      return contents[contents.length - 1];
    },
    isSelected() {
      return this.selectedPages.includes(this.pageId);
    },
  },
  methods: {
    loaded(_) {
      this.imageLoading = false;
    },
    refreshComponent() {
      this.$emit('refreshComponent');
    },
  },
};
</script>

<style lang="scss">
.page-selected-class {
  z-index: 300;
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  padding: 4px;
  width: 192px;
  height: 272px;
  background-color: #ecda5296;
  border: 1px solid #ee5050;
  border-radius: 10px;
}

.page-container {
  display: flex;
  width: 192px;
  border: 1px solid #e8e5e5;
  border-radius: 10px;
  padding: 4px;
}

.page-thumbnail {
  width: 182px;
  height: 255px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
