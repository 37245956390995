<template>
  <div style="padding: 4px; height: 300px" @click="clickedMe">
    <div class="page-container">
      <va-inner-loading :loading="imageLoading">
        <img
          class="page-thumbnail"
          :src="imageSource"
          loading="lazy"
          draggable="false"
          @load="loaded"
        />
      </va-inner-loading>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page',
  props: {
    imageSource: {
      type: String,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      imageLoading: true,
    };
  },
  methods: {
    loaded(_) {
      this.imageLoading = false;
    },
    clickedMe(event) {
      if (this.imageLoading) return;
      this.$emit('click', { event, index: this.index });
    },
  },
};
</script>

<style lang="scss">
.page-selected-class {
  z-index: 300;
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  padding: 4px;
  width: 192px;
  height: 272px;
  background-color: #ecda5296;
  border: 1px solid #ee5050;
  border-radius: 10px;
}

.page-container {
  display: flex;
  width: 192px;
  border: 1px solid #e8e5e5;
  border-radius: 10px;
  padding: 4px;
}

.page-thumbnail {
  width: 182px;
  height: 255px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
