<template>
  <div class="row">
    <div class="flex xs12">
      <loader v-if="isLoading" />
      <div v-else>
        <div class="row">
          <div class="flex md5 sm4 xs4">
            <img :src="getMangaCoverImage" class="manga-thumb" @click="$refs[`${selectedMangaId}_cover`].click()" />
            <input type="file" :ref="`${selectedMangaId}_cover`" style="display: none" accept="image/*"
              @change="uploadCover" />
          </div>
          <div class="flex md4 sm6 xs12">
            <p class="display-5">Manga Info</p>
            <br />
            <va-input v-model="manga.title" placeholder="Title" :disabled="view" />
            <br />
            <p class="title">Alternative titles</p>
            <tag-input v-model="manga.alternativeTitles" placeholder="Alternative titles" :disabled="view" />
            <br />
            <p class="title">Authors</p>
            <tag-input v-model="manga.authors" textKey="name" placeholder="Authors" :ajaxFunction="getPeopleArray"
              :disabled="view" />
            <br />
            <p class="title">Artists</p>
            <tag-input v-model="manga.artists" placeholder="Artists" textKey="name" :ajaxFunction="getPeopleArray"
              :disabled="view" />
            <br />
            <p class="title">Manga status</p>
            <toggle-switch :options="toggleSwitchOptions" @change="updateMangaStatus($event)"
              :value="selectedMangaStatus()" :disabled="view" />
            <br />
            <p class="title">Description</p>
            <textarea class="manga-description" v-model="manga.description" :disabled="view" />
            <p class="title">Tags</p>
            <tag-input v-model="manga.tags" placeholder="Tags" :disabled="view" />
          </div>
          <div class="flex md3 sm6 xs12">
            <p class="title">Publishing date</p>
            <va-date-picker v-model="manga.releaseDate" :disabled="view" />
            <br />
            <p class="display-6">Genres and Demographics</p>
            <br />
            <p class="title">Hentai and adult</p>
            <va-checkbox label="Hentai" v-model="manga.hentai" :disabled="view" />
            <va-checkbox label="Adult" v-model="manga.isAdult" :disabled="view" />
            <br />
            <p class="title">Select Format</p>
            <va-select :options="formatTypes" :value="manga.type.name" @input="updateMangaType" />
            <p class="title">Select theme</p>
            <tag-input v-model="manga.themes" textKey="name" placeholder="Themes" :ajaxFunction="getThemesArray"
              :disabled="view" />
            <p class="title">Select genres</p>
            <tag-input v-model="manga.genres" textKey="name" placeholder="Genres" :ajaxFunction="getGenresArray"
              :disabled="view" />
            <p class="title">Select demographics</p>
            <tag-input v-model="manga.demographics" textKey="name" placeholder="Demographics"
              :ajaxFunction="getDemographicsArray" :disabled="view" />
            <div class="manga-controls" v-if="!view">
              <va-toggle v-model="manga.disabled" :label="manga.disabled ? 'Enable manga' : 'Disable manga'" small />
              <va-button outline color="success" small @click="submitChanges()">
                Save Manga
              </va-button>
              <va-button outline color="danger" small @click="cancelEditing">
                {{ isMangaSaved ? 'Back' : 'Cancel' }}
              </va-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  fetchManga,
  createManga,
  updateManga,
} from '../../../apollo/api/mangas';
import { DEFAULT_MANGA } from '../../../constants/defaultValues';
import { mangaThumbFilter } from "../../../mixins/filters";
import { setTitle, saveMangaTitleInStorage } from '../../../mixins/utils';
import { fetchAuthors } from '../../../apollo/api/authors';
import { fetchGenres } from '../../../apollo/api/genres';
import ToggleSwitch from 'vuejs-toggle-switch';
import TagInput from '../../../components/TagInput';
import Loader from '../../../components/Loader';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'manga-details',
  components: { TagInput, Loader, ToggleSwitch },
  data() {
    return {
      manga: { ...DEFAULT_MANGA },
      loadedManga: { ...DEFAULT_MANGA },
      toggleSwitchOptions: {
        layout: {
          color: 'black',
          backgroundColor: this.$themes.secondary,
          selectedColor: 'white',
          borderColor: this.$themes.gray,
          squareCorners: false,
          noBorder: false,
          fontFamily: 'Arial',
          fontWeight: 'normal',
          fontWeightSelected: 'bold',
        },
        size: {
          fontSize: 1,
          height: 2,
          width: 20,
        },
        items: {
          preSelected: 'unknown',
          disabled: this.$route.name === 'view-manga',
          labels: [
            {
              name: 'On going',
              color: 'white',
              backgroundColor: this.$themes.success,
            },
            {
              name: 'Completed',
              color: 'white',
              backgroundColor: this.$themes.info,
            },
            {
              name: 'Dropped',
              color: 'white',
              backgroundColor: this.$themes.gray,
            },
          ],
        },
      },
      mangaFormats: [],
      coverImage: null,
    };
  },
  computed: {
    formatTypes() {
      return this.mangaFormats.map((e) => e.name);
    },
    getMangaCoverImage() {
      if (this.coverImage !== null) return URL.createObjectURL(this.coverImage);
      if (this.manga !== null) return mangaThumbFilter(this.manga.id);
      // TODO: Default manga thumbnail
      return 'https://dummyimage.com/400x600/000/fff';
    },
    ...mapGetters([
      'isLoading',
      'selectedMangaId',
      'selectedMangaMode',
      'isMangaSaved',
    ]),
    isAddMode() {
      return this.$route.name === 'add-manga';
    },
    view() {
      return this.selectedMangaMode === 'view' && !this.isAddMode;
    },
  },
  async mounted() {
    await this.getFormatArray();
    if (this.selectedMangaId && !this.isAddMode) {
      await this.loadMangaDetails();
    } else {
      const mangaIndex = _.findIndex(
        this.mangaFormats,
        (format) => format.name === 'Manga'
      );
      this.manga.type =
        mangaIndex > 0 ? this.mangaFormats[mangaIndex] : this.mangaFormats[0];
    }
  },
  watch: {
    manga: {
      handler: function (newVal, oldVal) {
        if (!_.isEqual(this.manga, this.loadedManga)) {
          this.setMangaSavedState(false);
        } else {
          this.setMangaSavedState(true);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations([
      'setLoading',
      'setBackgroundLoading',
      'setMangaSavedState',
      'setSelectedManga',
    ]),
    async loadMangaDetails() {
      this.setLoading(true);
      try {
        const { mangaInfo } = await fetchManga(this.selectedMangaId);
        this.manga = { ...DEFAULT_MANGA, ...mangaInfo };

        const mode = this.$route.name === 'view-manga' ? 'view' : 'edit';
        this.setSelectedManga({
          id: this.manga.id,
          title: this.manga.title,
          mode,
        });

        this.loadedManga = { ...DEFAULT_MANGA, ...mangaInfo };
        setTitle(`${this.manga.title} - ${this.view ? 'View' : 'Edit'}`);
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
    selectedMangaStatus() {
      switch (this.manga.status) {
        case 1:
          return 'On going';
        case 2:
          return 'Completed';
        case 3:
          return 'Dropped';
      }
    },
    updateMangaStatus(event) {
      this.manga.status = event.value;
    },
    updateMangaType(formatName) {
      const selectedFormat = this.mangaFormats.find(
        (e) => e.name === formatName
      );
      if (selectedFormat) {
        this.manga.type = selectedFormat;
      } else {
        alert('Invalid format selected');
      }
    },
    uploadCover({ target: { files = [] } }) {
      if (!files.length) {
        return;
      }
      this.coverImage = files[0];
    },
    async getPeopleArray(authorPattern = '') {
      this.setBackgroundLoading(true);
      const { peopleList } = await fetchAuthors(authorPattern, 5);
      this.setBackgroundLoading(true);
      return peopleList.people.map((p) => {
        return {
          id: p.id,
          text: p.name,
        };
      });
    },
    async getFormatArray() {
      this.setBackgroundLoading(true);
      const { genresList } = await fetchGenres('', 'format', 10);
      this.setBackgroundLoading(true);
      this.mangaFormats = genresList.genres.map((g) => {
        return {
          id: g.id,
          name: g.name,
        };
      });
    },
    async getGenresArray(genrePattern = '') {
      this.setBackgroundLoading(true);
      const { genresList } = await fetchGenres(genrePattern, '', 5);
      this.setBackgroundLoading(true);
      return genresList.genres.map((p) => {
        return {
          id: p.id,
          text: p.name,
        };
      });
    },
    async getThemesArray(genrePattern = '') {
      this.setBackgroundLoading(true);
      const { genresList } = await fetchGenres(genrePattern, 'theme', 5);
      this.setBackgroundLoading(true);
      return genresList.genres.map((p) => {
        return {
          id: p.id,
          text: p.name,
        };
      });
    },
    async getDemographicsArray(genrePattern = '') {
      this.setBackgroundLoading(true);
      const { genresList } = await fetchGenres(genrePattern, 'demographics', 5);
      this.setBackgroundLoading(true);
      return genresList.genres.map((p) => {
        return {
          id: p.id,
          text: p.name,
        };
      });
    },
    processInput(mangaInfo) {
      const manga = { ...mangaInfo };
      manga.alternativeTitles = mangaInfo.alternativeTitles.map((e) =>
        typeof e === 'object' ? e.text : e
      );
      // Setting the logic to set int status
      manga.status = 1;
      if (this.manga.status == 'Completed') {
        manga.status = 2;
      } else if (this.manga.status === 'Dropped') {
        manga.status = 3;
      }

      manga.authors = mangaInfo.authors.map((e) => e.id);
      manga.artists = mangaInfo.artists.map((e) => e.id);
      manga.genres = mangaInfo.genres.map((e) => e.id);
      manga.type = mangaInfo.type.id;
      manga.demographics = mangaInfo.demographics.map((e) => e.id);
      manga.themes = mangaInfo.themes.map((e) => e.id);
      manga.tags = mangaInfo.tags.map((e) =>
        typeof e === 'object' ? e.text : e
      );
      manga.releaseDate = new Date(mangaInfo.releaseDate);
      return manga;
    },
    async submitChanges() {
      const updatedManga = this.processInput(this.manga);
      let response = null;
      this.setLoading(true);

      try {
        if (this.selectedMangaId === null) {
          // Add as new manga
          response = await createManga(updatedManga, this.coverImage);
          this.$router.push({
            name: 'view-manga',
            params: { id: response.createManga.manga.id },
          });
        } else {
          response = await updateManga(
            this.selectedMangaId,
            updatedManga,
            this.coverImage
          );
          response = response.updateManga;
        }
        this.loadedManga = { ...DEFAULT_MANGA, ...response.manga };
        this.manga = { ...DEFAULT_MANGA, ...response.manga };
        this.showToast(
          `Manga ${!this.isAddMode ? 'updated' : 'added'} successfully`,
          {
            position: 'top-right',
            duration: 800,
            fullWidth: false,
          }
        );
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
    cancelEditing() {
      // Page check will be handled by the component, let it go...
      this.$router.push('/mangas/');
    },
  },
};
</script>

<style lang="scss">
.button-container {
  text-align: right;
}

.manga-thumb {
  width: 100%;
  border: 1px solid #e8e5e5;
  border-radius: 10px;
  transition: 100ms;
}

.row.row-inside {
  max-width: none;
}

.manga-controls {
  border: 2px dashed #e8e5e5;
  border-radius: 25px;
  margin-bottom: 10px;
}

.manga-description {
  flex: 1;
  width: 100%;
  border: 1px solid gray;
  border-radius: 10px;
  height: 150px;
  max-height: 150px;
  padding: 2px;
}
</style>
